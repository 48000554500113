import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Invoice from './views/invoice';

export default class App extends Component {
  state = {
    currentUrl: null
  };

  render = () => {
    return (
      <Router>
        <div className="App">
          <Switch>
              <Route path="/:currency" exact component={Invoice}></Route>
            </Switch>
        </div>
      </Router>
    );
  }
}