export const validateData = async (rows, invoice, to, from) => {
    // Validation rows
    if(rows.length < 1) return { error: "The inovice should have atleast one item added to it" };

    // Validation invoice
    if(invoice.invoiceNumber === "") return { error: "The inovice should have an invoice number" };
    if(invoice.dueDate === "") return { error: "The inovice should have an due date" };
    if(invoice.invoiceDate === "") return { error: "The inovice should have an invoice date" };

    // // Validate to
    if(to.companyName === "") return { error: "The inovice is missing the name of the customers Company" };
    if(to.addressedTo === "") return { error: "The inovice is missing the information to who the invoice is addressed" };
    if(to.street === "") return { error: "The inovice is missing the street name of your customer" };
    if(to.city === "") return { error: "The inovice is missing the city of your customer" };
    if(to.postalCode === "") return { error: "The inovice is missing the postalCode of your customer" };
    if(to.country === "") return { error: "The inovice is missing the country of your customer" };
    if(to.vat === "") return { error: "The inovice is missing the VAT info of your customer" };

    // // Validate from
    if(from.kvk === "") return { error: "The inovice is missing your" };
    if(from.vat === "") return { error: "The inovice is missing your VAT number" };
    if(from.street === "") return { error: "The inovice is missing your street information" };
    if(from.city === "") return { error: "The inovice is missing your city information" };
    if(from.country === "") return { error: "The inovice is missing the country of origin from your busines" };
    if(from.postalCode === "") return { error: "The inovice is missing your postalcode" };
    if(from.bank === "") return { error: "The inovice is missing the name of your bank" };
    if(from.iban === "") return { error: "The inovice is missing your IBAN number" };
    if(from.bic === "") return { error: "The inovice is missing your BIC code" };
    
    return { error: null };
}