import { validateData } from './validateData';
import { storeInvoice } from './storeInvoice';

export const generateXML = async (rows, invoice, to, from, exBTW, vat, incBTW) => {
	const validationErrors = await validateData(rows, invoice, to, from);
  
	if(validationErrors.error) {
		alert(validationErrors.error);
	} else {
		const xmlData = {
			invoice,
			to,
			from,
			rows,
			vatTotal: vat,
			exVatTotal: exBTW,
			incVatTotal: incBTW
		  };
		  
		  const xml = await prepareData(xmlData);
		  download(invoice, to, xml, from, rows);
	}
}

function download(invoice, to, xml, from, rows) {
    const fileName = invoice.invoiceNumber + "-" + to.companyName.replace('.','');

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(xml));
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

	document.body.removeChild(element);
	storeInvoice (invoice, from, to, rows);
}

async function prepareData(xmlData) {
    const json = xmlData;
	const rows = [];

	json.rows.forEach(row => {
		const tempData = {
			sequencenumber: row.id,
			amount: row.Amount,
			description: row.Description,
			amountexvat: Math.abs(row.Price).toFixed(2),
			costcentre_id: 0,
			vatamount: Math.abs(row.Price * (row.Vat / 100)).toFixed(2),
			amountincvat: Math.abs((Number(row.Price) * (Number(row.Vat) / 100)) + Number(row.Price)).toFixed(2)
		};
		rows.push(tempData);
	});
		
	const data = {
		invoiceNummber: json.invoice.invoiceNumber,
		IssueDate: json.invoice.invoiceDate,
		TaxPointDate: json.invoice.invoiceDate,
		DocumentCurrencyCode: json.invoice.currency,
		LineCountNumeric: rows.length,
		DocumentCurrencyCode: json.invoice.currency,
		AccountingSupplierParty: {
			Btw: json.from.vat,
			Kvk: json.from.kvk,
			Name: json.from.companyName,
			StreetName: json.from.street,
			CityName: json.from.city,
			PostalZone: json.from.postalCode,
			CountrySubentityCode: "GL",
			IdentificationCode: "NL",
			Country: json.from.country,
			Telephone: json.from.tel,
			ElectronicMail: json.from.eMail,
			SupplierAssignedAccountID: "",
		},
		CustomerSupplierParty: {
			Btw: json.to.vat,
			Kvk: json.to.kvk,
			Name: json.to.name,
			StreetName: json.to.street,
			CityName: json.to.city,
			PostalZone: json.to.postalCode,
			CountrySubentityCode: "GL",
			IdentificationCode: "NL",
			Country: json.to.country,
			Telephone: json.to.tel,
			ElectronicMail: json.to.eMail,
		},
		PaymentDueDate: json.invoice.dueDate,
		Bic: json.from.bic,
		Iban: json.from.iban,
		PaymentTerms: json.invoice.remarksAndConditions,
		TaxAmount: json.vatTotal,
		TaxableAmount: json.exVatTotal,
		Percent: '21.00',
		TotalInc: json.incVatTotal,
		TotalExBtw: json.exVatTotal,
		InvoiceLine: rows
	};
	return generateExactXML(data);
}

function generateExactXML(data) {
	let xmlStr = "<Invoice xmlns:qdt=\"urn:oasis:names:specification:ubl:schema:xsd:QualifiedDatatypes-2\" xmlns:ccts=\"urn:oasis:names:specification:ubl:schema:xsd:CoreComponentParameters-2\" xmlns:stat=\"urn:oasis:names:specification:ubl:schema:xsd:DocumentStatusCode-1.0\" xmlns:cbc=\"urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2\" xmlns:cac=\"urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2\" xmlns:udt=\"urn:un:unece:uncefact:data:draft:UnqualifiedDataTypesSchemaModule:2\" xmlns=\"urn:oasis:names:specification:ubl:schema:xsd:Invoice-2\">";
		xmlStr += "<cbc:UBLVersionID>2.0<\/cbc:UBLVersionID>";
		xmlStr += "<cbc:CustomizationID>1.0<\/cbc:CustomizationID>";
		xmlStr += "<cbc:ProfileID>ExactOnline<\/cbc:ProfileID>";
		xmlStr += `<cbc:ID>${data.invoiceNummber}<\/cbc:ID>`;
		xmlStr += `<cbc:IssueDate>${data.IssueDate}<\/cbc:IssueDate>`;
		xmlStr += "<cbc:InvoiceTypeCode>1<\/cbc:InvoiceTypeCode>";
		xmlStr += "<cbc:Note><\/cbc:Note>";
		xmlStr += `<cbc:TaxPointDate>${data.invoiceNummber}<\/cbc:TaxPointDate>`;
		xmlStr += `<cbc:DocumentCurrencyCode>${data.DocumentCurrencyCode}<\/cbc:DocumentCurrencyCode>`;
		xmlStr += `<cbc:LineCountNumeric>${data.LineCountNumeric}<\/cbc:LineCountNumeric>`;
		xmlStr += "<cac:OrderReference />";
		xmlStr += "<cac:AccountingSupplierParty>";
			xmlStr += "<cac:Party>";
				xmlStr += "<cac:PartyIdentification>";
					xmlStr += `<cbc:ID schemeAgencyID=\"NL\" schemeAgencyName=\"BTW\">${data.AccountingSupplierParty.Btw}<\/cbc:ID>`;
				xmlStr += "<\/cac:PartyIdentification>";
				xmlStr += "<cac:PartyIdentification>";
					xmlStr += `<cbc:ID schemeAgencyID=\"NL\" schemeAgencyName=\"KVK\">${data.AccountingSupplierParty.Kvk}<\/cbc:ID>`;
				xmlStr += "<\/cac:PartyIdentification>";
				xmlStr += `<cac:PartyName><cbc:Name>${data.AccountingSupplierParty.Name}<\/cbc:Name><\/cac:PartyName>`;
				xmlStr += "<cac:PostalAddress>"
					xmlStr += `<cbc:StreetName>${data.AccountingSupplierParty.StreetName}<\/cbc:StreetName><cbc:CityName>${data.AccountingSupplierParty.CityName}<\/cbc:CityName><cbc:PostalZone>${data.AccountingSupplierParty.PostalZone}<\/cbc:PostalZone><cbc:CountrySubentity\/><cbc:CountrySubentityCode>${data.AccountingSupplierParty.CountrySubentityCode}<\/cbc:CountrySubentityCode><cac:Country><cbc:IdentificationCode>${data.AccountingSupplierParty.IdentificationCode}<\/cbc:IdentificationCode><cbc:Name>${data.AccountingSupplierParty.Country}<\/cbc:Name><\/cac:Country>`;
				xmlStr += "<\/cac:PostalAddress>";
				xmlStr += `<cac:PartyTaxScheme><cbc:CompanyID>${data.AccountingSupplierParty.Btw}<\/cbc:CompanyID><cac:TaxScheme><cbc:ID>VAT<\/cbc:ID><\/cac:TaxScheme><\/cac:PartyTaxScheme>`;
				xmlStr += `<cac:PartyLegalEntity><cbc:CompanyID>${data.AccountingSupplierParty.Kvk}<\/cbc:CompanyID><\/cac:PartyLegalEntity>`;
				xmlStr += `<cac:Contact><cbc:Telephone>${data.AccountingSupplierParty.Telephone}<\/cbc:Telephone><cbc:Telefax\/><cbc:ElectronicMail>${data.AccountingSupplierParty.ElectronicMail}<\/cbc:ElectronicMail><\/cac:Contact>`;
			xmlStr += "<\/cac:Party>";
		xmlStr += "<\/cac:AccountingSupplierParty>";
		xmlStr += "<cac:AccountingCustomerParty><cac:Party>";
			xmlStr += `<cbc:SupplierAssignedAccountID>${data.AccountingSupplierParty.SupplierAssignedAccountID}<\/cbc:SupplierAssignedAccountID>`;
			xmlStr += "<cac:PartyIdentification>";
				xmlStr += `<cbc:ID schemeAgencyID=\"NL\" schemeAgencyName=\"BTW\">${data.CustomerSupplierParty.Btw}<\/cbc:ID>`;
			xmlStr += "<\/cac:PartyIdentification>";
			xmlStr += "<cac:PartyIdentification>";
				xmlStr += `<cbc:ID schemeAgencyID=\"NL\" schemeAgencyName=\"KVK\">${data.CustomerSupplierParty.Kvk}<\/cbc:ID>`;
			xmlStr += "<\/cac:PartyIdentification>";
			xmlStr += `<cac:PartyName><cbc:Name>${data.CustomerSupplierParty.Name}<\/cbc:Name><\/cac:PartyName>`;
			xmlStr += "<cac:PostalAddress>";
				xmlStr += `<cbc:StreetName>${data.CustomerSupplierParty.StreetName}<\/cbc:StreetName><cbc:CityName>${data.CustomerSupplierParty.CityName}<\/cbc:CityName><cbc:PostalZone>${data.CustomerSupplierParty.PostalZone}<\/cbc:PostalZone><cac:Country><cbc:IdentificationCode>${data.CustomerSupplierParty.IdentificationCode}<\/cbc:IdentificationCode><cbc:Name>${data.CustomerSupplierParty.Country}<\/cbc:Name><\/cac:Country>`;
			xmlStr += "<\/cac:PostalAddress>";
			xmlStr += "<cac:PartyTaxScheme>";
				xmlStr += `<cbc:CompanyID>${data.CustomerSupplierParty.Btw}<\/cbc:CompanyID><cac:TaxScheme><cbc:ID>VAT<\/cbc:ID><\/cac:TaxScheme>`;
			xmlStr += "<\/cac:PartyTaxScheme>";
			xmlStr += `<cac:Contact><cbc:Name>Invoices<\/cbc:Name><cbc:Telephone>${data.CustomerSupplierParty.Telephone}<\/cbc:Telephone><cbc:Telefax\/><cbc:ElectronicMail>${data.CustomerSupplierParty.ElectronicMail}<\/cbc:ElectronicMail><\/cac:Contact>`;
		xmlStr += "<\/cac:Party><\/cac:AccountingCustomerParty>";
		xmlStr += "<cac:PaymentMeans>";
			xmlStr += "<cbc:PaymentMeansCode listID=\"UN\/ECE 4461\" listName=\"Payment Means\" listURI=\"http:\/\/docs.oasis-open.org\/ubl\/os-UBL-2.0-update\/cl\/gc\/default\/PaymentMeansCode-2.0.gc\">1<\/cbc:PaymentMeansCode>";
			xmlStr += `<cbc:PaymentDueDate>${data.PaymentDueDate}<\/cbc:PaymentDueDate><cbc:InstructionNote><\/cbc:InstructionNote>`;
			xmlStr += "<cac:PayeeFinancialAccount>";
				xmlStr += `<cbc:ID schemeName=\"IBAN\">${data.Iban}<\/cbc:ID>`;
				xmlStr += "<cac:FinancialInstitutionBranch>";
					xmlStr += "<cac:FinancialInstitution>";
						xmlStr += `<cbc:ID schemeName=\"BIC\">${data.Bic}<\/cbc:ID>`;
					xmlStr += "<\/cac:FinancialInstitution>";
				xmlStr += "<\/cac:FinancialInstitutionBranch>";
			xmlStr += "<\/cac:PayeeFinancialAccount>";
			xmlStr += "<cac:PaymentTerms>";
				xmlStr += `<cbc:Note>${data.PaymentTerms}<\/cbc:Note>`;
			xmlStr += "<\/cac:PaymentTerms>";
		xmlStr += "<\/cac:PaymentMeans>";
		xmlStr += "<cac:TaxTotal>";
			xmlStr += `<cbc:TaxAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${data.TaxAmount}<\/cbc:TaxAmount>`;
			xmlStr += "<cac:TaxSubtotal>";
				xmlStr += `<cbc:TaxableAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${data.TaxableAmount}<\/cbc:TaxableAmount>`;
				xmlStr += `<cbc:TaxAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${data.TaxAmount}<\/cbc:TaxAmount>`;
				xmlStr += `<cbc:Percent>${data.Percent}<\/cbc:Percent>`;
				xmlStr += "<cac:TaxCategory>";
					xmlStr += "<cbc:ID schemeID=\"UN\/EDIFACT 5305\" schemeName=\"Duty or tax or fee category\"\/><cbc:TaxExemptionReason\/>";
					xmlStr += "<cac:TaxScheme><cbc:ID>VAT<\/cbc:ID><\/cac:TaxScheme>";
					xmlStr += "<cbc:Name>1A<\/cbc:Name>";
				xmlStr += "<\/cac:TaxCategory>";
			xmlStr += "<\/cac:TaxSubtotal>";
		xmlStr += "<\/cac:TaxTotal>";
		xmlStr += "<cac:LegalMonetaryTotal>";
			xmlStr += `<cbc:LineExtensionAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${data.TotalExBtw}<\/cbc:LineExtensionAmount>`;
			xmlStr += `<cbc:TaxExclusiveAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${data.TotalExBtw}<\/cbc:TaxExclusiveAmount>`;
			xmlStr += `<cbc:TaxInclusiveAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${data.TotalInc}<\/cbc:TaxInclusiveAmount>`;
			xmlStr += `<cbc:PayableAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${data.TotalInc}<\/cbc:PayableAmount>`;
		xmlStr += "<\/cac:LegalMonetaryTotal>";

		let i = 0;
		for (const element of data.InvoiceLine) {
			i++;
			xmlStr += "<cac:InvoiceLine>";
				xmlStr += `<cbc:ID>${element.sequencenumber}<\/cbc:ID>`;
				xmlStr += `<cbc:Note>${element.description}<\/cbc:Note>`;
				xmlStr += `<cbc:InvoicedQuantity>${element.amount ? element.amount : 1}<\/cbc:InvoicedQuantity>`;
				xmlStr += `<cbc:LineExtensionAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${element.amount ? element.amount * element.amountexvat : element.amountexvat}<\/cbc:LineExtensionAmount>`;
				xmlStr += `<cbc:AccountingCostCode>${element.costcentre_id}<\/cbc:AccountingCostCode>`;
				xmlStr += `<cbc:AccountingCost>"<\/cbc:AccountingCost>`;
				xmlStr += `<cac:TaxTotal>`;
					xmlStr += `<cbc:TaxAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${element.amount ? element.amount * element.vatamount : element.vatamount}<\/cbc:TaxAmount>`;
					xmlStr += `<cac:TaxSubtotal>`;
						xmlStr += `<cbc:TaxableAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${element.amount ? element.amount * element.amountexvat : element.amountexvat}<\/cbc:TaxableAmount>`;
						xmlStr += `<cbc:TaxAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${element.amount ? element.amount * element.vatamount : element.vatamount}<\/cbc:TaxAmount>`;
						xmlStr += `<cbc:Percent>${((Math.abs(element.amountincvat / element.amountexvat) - 1) * 100).toFixed(0)}<\/cbc:Percent>`;
						xmlStr += `<cac:TaxCategory>`;
							xmlStr += `<cbc:ID schemeID=\"UN\/EDIFACT 5305\" schemeName=\"Duty or tax or fee category\"\/><cbc:Name>1A<\/cbc:Name>`;
							xmlStr += `<cbc:TaxExemptionReason\/>`;
							xmlStr += `<cac:TaxScheme><cbc:ID>VAT<\/cbc:ID><\/cac:TaxScheme>`;
						xmlStr += `<\/cac:TaxCategory>`;
					xmlStr += `<\/cac:TaxSubtotal>`;
				xmlStr += `<\/cac:TaxTotal>`;
				xmlStr += `<cac:Item>`;
					xmlStr += `<cbc:Description>${element.description}<\/cbc:Description>`;
					xmlStr += `<cbc:Name>${element.description}<\/cbc:Name>`;
					xmlStr += `<cbc:AdditionalInformation\/><cac:SellersItemIdentification><cbc:ID>${i}<\/cbc:ID><\/cac:SellersItemIdentification>`;
				xmlStr += `<\/cac:Item>`;
				xmlStr += `<cac:Price>`;
					xmlStr += `<cbc:PriceAmount currencyID=\"${data.DocumentCurrencyCode.toUpperCase()}\">${element.amountexvat}<\/cbc:PriceAmount>`;
					xmlStr += `<cbc:BaseQuantity>1.00<\/cbc:BaseQuantity>`;
				xmlStr += `<\/cac:Price>`;
			xmlStr += `<\/cac:InvoiceLine>`;
		};

	xmlStr = xmlStr + "<\/Invoice>";
    return xmlStr;
}