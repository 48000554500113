export const storeInvoice = async (invoice, from, to, rows) => {
    let invoices = await JSON.parse(localStorage.getItem('storedInvoices'));

    if(!invoices) invoices = {};

    invoices[invoice.invoiceNumber] = {
        invoice,
        from,
        to,
        rows
    }

    localStorage.setItem('storedInvoices', JSON.stringify(invoices));
}

export const getStoredInvoices = async () => {
    const invoices = await JSON.parse(localStorage.getItem('storedInvoices'));

    const selectOptions = [];

    if(invoices) {
        Object.keys(invoices).map(invoice => {
            selectOptions.push(
                { value: invoices[invoice], label: invoice + " - " + invoices[invoice].to.companyName + " - " + invoices[invoice].invoice.invoiceDate  }
            );
        })

        return selectOptions;
    }

    return [];
}