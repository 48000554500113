export const getTotalExVat = (rows) => {
    let exVat = 0;

    for (const row of rows) {
      exVat += row.Price * row.Amount;
    }

    return Math.abs(exVat).toFixed(2);
}

export const getTotalVat = (rows) => {
    let vat = 0;

    for (const row of rows) {
      vat += row.Price * row.Amount * (row.Vat / 100);
    }

    return Math.abs(vat).toFixed(2);
}

export const getTotalIncVat = (rows) => {
    let incVat = 0;

    for (const row of rows) {
        incVat += (row.Price * row.Amount * (row.Vat / 100)) + (row.Price * row.Amount);
    }

    return Math.abs(incVat).toFixed(2);
}