import { toData } from '../constants/to';

export const getToData = async (param) => {
    if("uk" === param || "holding" === param || "nl" === param || "group" === param || "pl" === param) {
        return toData[param];
    } 

    if(localStorage.getItem('to')) {
        return JSON.parse(localStorage.getItem('to'));
    } 
    
    let data = {
        companyName: 'Merapar Technologies Netherlands B.V.',
        addressedTo: 'Finance',
        street: 'De Run 8417',
        city: 'Veldhoven',
        postalCode: '5504EP',
        country: 'Nederland',
        vat: 'NL856169420B01',
    };

    if ("gbp" === param) {
        data = {
            companyName: 'Merapar Technologies UK Ltd.',
            addressedTo: 'Finance',
            street: '453 Cranbrook Road',
            city: 'Ilford',
            postalCode: 'IG2 6EW',
            country: 'United Kingdom',
            vat: 'GB316277307',
        };
    }

    return data;
}