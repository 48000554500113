export const getFromData = () => {
    if(localStorage.getItem('from')) {
        return JSON.parse(localStorage.getItem('from'));
    }

    return {
        companyName: '',
        kvk: '',
        vat: '',
        street: '',
        city: '',
        country: '',
        postalCode: '',
        bank: '',
        iban: '',
        bic: '',
        tel: '',
        eMail: ''
    }
}