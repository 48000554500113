import React from 'react';
import ImageUploader from 'react-images-upload';
import Select from 'react-select';
import { getTotalVat, getTotalExVat, getTotalIncVat } from '../functions/calculateTotal';
import { generatePdf } from '../functions/generatePDF';
import { generateXML } from '../functions/generateXML';
import { getToData } from '../functions/getToData';
import { getFromData } from '../functions/getFromData';
import { getInvoiceData } from '../functions/getInvoiceData';
import { getRowData } from '../functions/getRowData';
import { defaultState } from '../constants/defaultState';
import { getStoredInvoices } from '../functions/storeInvoice';
import '../App.css';

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState
    };

    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount = async () => {
    const { match: { params } } = this.props;

    this.setState({
      to: await getToData(params.currency), 
      from: await getFromData(),
      storedInvoices: await getStoredInvoices(),
       ...getInvoiceData(params),
      ...getRowData()
    });
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
        pictures: pictureDataURLs
    });
  }

  addRow() {
    const { rows, addRow } = this.state;

    const NewRows = rows;
    const tempAddrow = addRow;

    tempAddrow.Subtotal = tempAddrow.Price * tempAddrow.Amount;

    NewRows.push(addRow);

    let rowID = 0;
    rows.forEach(element => {
      if(element.id >= rowID) {
        rowID = element.id;
      }
    });

    this.setState({
      rows: NewRows,
      addRow: {
        id: rowID + 1,
        Description: '',
        Amount: '',
        Price: '',
        Vat: '',
        Subtotal: ''
      }
    });
    localStorage.setItem('rows', JSON.stringify(NewRows));
  }

  deleteRow(id) {
    const { rows } = this.state;

    const NewRows = rows;
    let i = 0;

    for(const row of NewRows) {
      if(row.id === id) {
        NewRows.splice(i, 1);
      }
      i++;
    }

    this.setState({
      rows: NewRows
    });
    localStorage.setItem('rows', JSON.stringify(NewRows));
  }

  editRow(id) {
    const { rows } = this.state;

    const NewRows = rows;
    let tempRow = null;

    let i = 0;

    for(const row of NewRows) {
      if(row.id === id) {
        tempRow = row;
        NewRows.splice(i, 1);
      }
      i++;
    }

    this.setState({
      rows: NewRows,
      addRow: {
        id: tempRow.id,
        Description: tempRow.Description,
        Amount: tempRow.Amount,
        Price: tempRow.Price,
        Vat: tempRow.Vat,
        Subtotal: tempRow.Subtotal
      }
    });
    localStorage.setItem('rows', JSON.stringify(NewRows));
  }

  updateRow(key, value) {
    const { addRow } = this.state;

    const tempRow = addRow;
    tempRow[key] = value;

    this.setState({
      addRow, tempRow
    });
  }

  updateTo(key, value) {
    const { to } = this.state;

    const tempTo = to;
    tempTo[key] = value;

    this.setState({
      to, tempTo
    });
    localStorage.setItem('to', JSON.stringify(tempTo));
  }

  updateFrom(key, value) {
    const { from } = this.state;

    const tempFrom = from;
    tempFrom[key] = value;

    this.setState({
      from, tempFrom
    });
    localStorage.setItem('from', JSON.stringify(tempFrom));
  }

  updateInvoice(key, value) {
    const { invoice } = this.state;

    const tempInvoice = invoice;
    tempInvoice[key] = value;

    this.setState({
      invoice, tempInvoice
    });
    localStorage.setItem('invoice', JSON.stringify(tempInvoice));
  }

  selectInvoice(value) {
    this.setState({...value.value})
  }

  render() {
    const { rows, addRow, to, from, invoice, pictures, currencySymbol, optionsVat, storedInvoices } = this.state;

    return (
      <div className="App">
        <header className="App-header">
        <center><img width="300" style={{marginTop: 40}} height="68" src="https://merapar.com/wp-content/uploads/2018/12/logo-licht-300x68.png" className="attachment-medium size-medium" alt="" srcSet="https://merapar.com/wp-content/uploads/2018/12/logo-licht-300x68.png 300w, https://merapar.com/wp-content/uploads/2018/12/logo-licht.png 524w" sizes="(max-width: 300px) 100vw, 300px"/></center>
            <div style={{width: "70vw", minWidth: 600, backgroundColor: 'white', borderRadius: 8, margin: 40}}>
              <div style={{marginTop: 40, marginBottom: 20}}>
                <label><b>Previous invoices:</b></label><br/><br/>
                <div style={{width: "60%", marginLeft: "20%"}}>
                  <Select options={storedInvoices} onChange={(e) => this.selectInvoice(e)} />
                </div>
              </div>
              <div className="formInputs" style={{float: "left", width: "30vw", minWidth: 260, textAlign: 'left', margin: 20, marginTop: 30}}>
                <label><b>To:</b></label><br/><br/>

                <label>Company name: <input type="text" name="name" value={to.companyName} onChange={(e) => this.updateTo("companyName", e.target.value)}/></label><br/>
                <label>Addressed to: <input type="text" name="name" value={to.addressedTo} onChange={(e) => this.updateTo("addressedTo", e.target.value)}/></label><br/><br/>

                <label>Street: <input type="text" name="name" value={to.street} onChange={(e) => this.updateTo("street", e.target.value)}/></label><br/>
                <label>City: <input type="text" name="name" value={to.city} onChange={(e) => this.updateTo("city", e.target.value)}/></label><br/>
                <label>Postalcode: <input type="text" name="name" value={to.postalCode} onChange={(e) => this.updateTo("postalCode", e.target.value)}/></label><br/>

                <label>Country: <input type="text" name="name" value={to.country} onChange={(e) => this.updateTo("country", e.target.value)}/></label><br/><br/>
                <label>VAT: <input type="text" name="name" value={to.vat} onChange={(e) => this.updateTo("vat", e.target.value)}/></label><br/><br/><br/>

                <label><b>Invoice:</b></label><br/><br/>

                <label>Invoice number: <input type="text" name="name" value={invoice.invoiceNumber} onChange={(e) => this.updateInvoice("invoiceNumber", e.target.value)}/></label><br/>
                <label>Invoice date: <input type="date" name="name" value={invoice.invoiceDate} onChange={(e) => this.updateInvoice("invoiceDate", e.target.value)}/></label><br/><br/>
                <label>Due date: <input type="date" name="name" value={invoice.dueDate} onChange={(e) => this.updateInvoice("dueDate", e.target.value)}/></label><br/><br/>
                <label>Your reference: <input type="text" name="name" value={invoice.yourRefference} onChange={(e) => this.updateInvoice("yourRefference", e.target.value)}/></label><br/>
                <label>Remark & Conditions: <input type="text" name="name" value={invoice.remarksAndConditions} onChange={(e) => this.updateInvoice("remarksAndConditions", e.target.value)}/></label><br/>
              </div>
              <div className="formInputs" style={{float: "left", width: "30vw", minWidth: 260, textAlign: 'left', margin: 20, marginTop: 30}}>
                <label><b>From:</b></label><br/><br/>
                <label>Background image invoice</label><br/>
                {
                  pictures.length === 0 ? (
                    <ImageUploader
                      withIcon={true}
                      buttonText='Choose image'
                      onChange={this.onDrop}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                    />
                  ) : (
                    <div>
                      <img src={pictures[0]} className="imagePreview" alt="preview of pdf watermark" height="200" width="140"/>
                      <a onClick={() => this.setState({ pictures: [] })}>remove</a>
                    </div>
                  )
                }
                <label>Company name: <input type="text" name="name" value={from.companyName} onChange={(e) => this.updateFrom("companyName", e.target.value)}/></label><br/>
                <label>KVK: <input type="text" name="name" value={from.kvk} onChange={(e) => this.updateFrom("kvk", e.target.value)}/></label><br/>
                <label>VAT: <input type="text" name="name" value={from.vat} onChange={(e) => this.updateFrom("vat", e.target.value)}/></label><br/><br/>

                <label>Street: <input type="text" name="name" value={from.street} onChange={(e) => this.updateFrom("street", e.target.value)}/></label><br/>
                <label>City: <input type="text" name="name" value={from.city} onChange={(e) => this.updateFrom("city", e.target.value)}/></label><br/>
                <label>Postalcode: <input type="text" name="name" value={from.postalCode} onChange={(e) => this.updateFrom("postalCode", e.target.value)}/></label><br/>
                <label>Country: <input type="text" name="name" value={from.country} onChange={(e) => this.updateFrom("country", e.target.value)}/></label><br/><br/>

                <label>Bank: <input type="text" name="name" value={from.bank} onChange={(e) => this.updateFrom("bank", e.target.value)}/></label><br/>
                <label>IBAN: <input type="text" name="name" value={from.iban} onChange={(e) => this.updateFrom("iban", e.target.value)}/></label><br/>
                <label>BIC: <input type="text" name="name" value={from.bic} onChange={(e) => this.updateFrom("bic", e.target.value)}/></label><br/><br/>

                <label>Tel: <input type="text" name="name" value={from.tel} onChange={(e) => this.updateFrom("tel", e.target.value)}/></label><br/>
                <label>E-mail: <input type="text" name="name" value={from.eMail} onChange={(e) => this.updateFrom("eMail", e.target.value)}/></label><br/><br/>
              </div>
              <div className="secondInputDiv formInputs" style={{textAlign: 'left', margin: 10, marginBottom: 30, clear: 'both', width: "30vw", minWidth: 260}}>
                
              </div>
              <table style={{width: '67vw', minWidth: 600, margin: 10}}>
                <thead>
                  <tr>
                    <th>&#9999;</th>
                    <th>-/+</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Vat</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  { 
                    rows.sort(function(a, b) { 
                      return a.id - b.id;
                    }).map(element => {
                      return (
                        <tr key={element.id}>
                          <td style={{textAlign: "left"}}><a onClick={() => this.editRow(element.id)}>&#9999;</a></td>
                          <td style={{textAlign: "left"}}><a onClick={() => this.deleteRow(element.id)}>-</a></td>
                          <td style={{textAlign: "left"}}>{element.Description}</td>
                          <td style={{textAlign: "right"}}>{element.Amount}</td>
                          <td style={{textAlign: "right"}}>{currencySymbol} {Math.abs(element.Price).toFixed(2)}</td>
                          <td style={{textAlign: "right"}}>{element.Vat} %</td>
                          <td style={{textAlign: "right"}}>{currencySymbol} {Math.abs(element.Subtotal).toFixed(2)}</td>
                        </tr>
                      );
                    })
                  }
                  <tr>
                    <td></td>
                    <td><a onClick={() => this.addRow()}>+</a></td>
                    <td><input type="text" name="Description" value={addRow.Description} onChange={(e) => this.updateRow("Description", e.target.value)}/></td>
                    <td><input type="text" name="Quantity" value={addRow.Amount} onChange={(e) => this.updateRow("Amount", e.target.value)} /></td>
                    <td><input type="text" name="Price" value={addRow.Price} onChange={(e) => this.updateRow("Price", e.target.value)} /></td>
                    <td>
                      <Select style={{fontSize: "large", borderRadius: 4, color: "#555", paddingLeft: 5}} options={optionsVat} onChange={(e) => this.updateRow("Vat", e.value)} />
                    </td>
                    <td></td>
                  </tr>
                  <tr style={{height: 20}}></tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{textAlign: "right"}}><b>Total Ex Vat</b></td>
                    <td style={{textAlign: "right"}}>{currencySymbol} {getTotalExVat(rows)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{textAlign: "right"}}><b>Total Vat</b></td>
                    <td style={{textAlign: "right"}}>{currencySymbol} {getTotalVat(rows)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{textAlign: "right"}}><b>Total Inc Vat</b></td>
                    <td style={{textAlign: "right"}}>{currencySymbol} {getTotalIncVat(rows)}</td>
                  </tr>
                </tbody>
              </table>
              <br/>
            </div>
            <div style={{width: '310px'}}>
              <button onClick={() => generatePdf(rows, invoice, to, from, pictures, currencySymbol, getTotalExVat(rows), getTotalVat(rows), getTotalIncVat(rows))} style={{fontSize: 20, fontWeight: 'bolder', borderRadius: 4, marginRight: 40}}>Create PDF</button>
              <button onClick={() => generateXML(rows, invoice, to, from, getTotalExVat(rows), getTotalVat(rows), getTotalIncVat(rows))} style={{fontSize: 20, fontWeight: 'bolder', borderRadius: 4}}>Create XML</button>
            </div>
            <br/>
            <br/>
            <br/>
        </header>
      </div>
    );
  }
}

export default Invoice;
