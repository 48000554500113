export const getRowData = () => {
    if(localStorage.getItem('rows')) {
        const tempRows = JSON.parse(localStorage.getItem('rows'));
        let rowID = 0;
  
        tempRows.forEach(element => {
          if(element.id >= rowID) {
            rowID = element.id;
          }
        });
  
        return{
          rows: tempRows,
          addRow: {
            id: rowID + 1,
            Description: '',
            Amount: '',
            Price: '',
            Vat: '',
            Subtotal: ''
          }
        };
      }
}