let tempCurrencySymbol = '€';
let tempVatOptions = [
  { value: '21', label: '21%' },
  { value: '0', label: '0%' }
];

export const getInvoiceData = (params) => {
    if(localStorage.getItem('invoice')) {
        const tempInvoice = JSON.parse(localStorage.getItem('invoice'));
        tempInvoice.currency = params.currency;
  
        if ("gbp" === params.currency || "uk" === params.currency) {
          tempCurrencySymbol = '£';
          tempVatOptions = [
            { value: '20', label: '20%' },
            { value: '0', label: '0%' }
          ]
        }
        
        return {invoice: tempInvoice, currencySymbol: tempCurrencySymbol, optionsVat: tempVatOptions};
      } else {
        if ("gbp" === params.currency || "uk" === params.currency) {
          tempCurrencySymbol = '£';
          tempVatOptions = [
            { value: '20', label: '20%' },
            { value: '0', label: '0%' }
          ]
        }
  
        return {currencySymbol: tempCurrencySymbol, optionsVat: tempVatOptions};
    }
}