export const defaultState = {
    rows: [],
    pictures: [],
    currencySymbol: '€',
    optionsVat: [
        { value: '21', label: '21%' },
        { value: '0', label: '0%' }
    ],
    addRow: {
        id: 0,
        Description: '',
        Amount: '',
        Price: '',
        Vat: '',
        Subtotal: ''
    },
    to: {
        companyName: '',
        addressedTo: '',
        street: '',
        city: '',
        postalCode: '',
        country: '',
        vat: '',
    },
    from: {
        companyName: '',
        kvk: '',
        vat: '',
        street: '',
        city: '',
        country: '',
        postalCode: '',
        bank: '',
        iban: '',
        bic: '',
        tel: '',
        eMail: ''
    },
    invoice: {
        invoiceNumber: '',
        invoiceDate: '',
        dueDate: '',
        yourRefference: '',
        remarksAndConditions: '',
        currency: 'eur'
    },
    storedInvoices: []
}