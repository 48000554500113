import jsPDF from 'jspdf';
import { validateData } from './validateData';
import { storeInvoice } from './storeInvoice';

export const generatePdf = async (rows, invoice, to, from, pictures, currencySymbol, exBTW, vat, incBTW) => {
  const validationErrors = await validateData(rows, invoice, to, from);

  if(validationErrors.error) {
		alert(validationErrors.error);
	} else {  
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

      const tempIssueDate = new Date(invoice.invoiceDate);
      const tempDueDate = new Date(invoice.dueDate);
      
      const issueData = tempIssueDate.toLocaleDateString('en-GB', options);
      const dueDate = tempDueDate.toLocaleDateString('en-GB', options);

      var doc = new jsPDF();

      if(pictures.length > 0) {
        console.log();
        var imgData = pictures[0].match(/data:image\/png;name=.+;base64,(.+)/i)[1];
        doc.addImage(imgData, 'PNG', 0, 0, 210, 300);
      } else {
        doc.setFontSize(20);
        doc.text(from.companyName, doc.internal.pageSize.width / 2, 15, null, null, 'center');
      }

      doc.setFontSize(10);

      doc.setFontType("bold");
      doc.text(120, 59, 'Invoice from:');
      doc.setFontType("normal");

      doc.text(120, 66, from.companyName);
      doc.text(120, 71, from.street);
      doc.text(120, 76, from.postalCode + " " + from.city);
      doc.text(120, 81, from.country);
      
      doc.text(120, 86, "Phone: " + from.tel);
      doc.text(120, 91, "E-mail: " + from.eMail);

      doc.text(120, 38, 'Invoice number:');
      doc.text(160, 38, invoice.invoiceNumber);
      doc.text(120, 43, 'Issue date:');
      doc.text(160, 43, issueData);
      doc.text(120, 48, 'Due date:');
      doc.text(160, 48, dueDate);
      doc.setFontType("bold");
      doc.text(15, 59, 'Invoice to:');
      doc.setFontType("normal");
      doc.text(15, 66, to.companyName);
      doc.text(15, 71, to.addressedTo);
      doc.text(15, 76, to.street);
      doc.text(15, 81, to.postalCode + " " + to.city);
      doc.text(15, 86, to.country);
      doc.text(15, 91, 'VAT: ' + to.vat);

      doc.setFontSize(28);

      doc.setFontType("bold");
      doc.text(15, 120, 'Invoice');
    
      doc.setFontSize(10);

      doc.setLineWidth(0.25);
      
      doc.line(10, 127, 200, 127);

      doc.text(15, 132, 'Description');
      doc.text(110, 132, 'Quantity', {align:'right'});
      doc.text(140, 132, 'Unit price', {align:'right'});
      doc.text(170, 132, 'VAT Rate', {align:'right'});
      doc.text(198, 132, 'Amount', {align:'right'});

      doc.setFontType("normal");

      doc.line(10, 135, 200, 135);

      let i = 135;

      rows.forEach(element => {
        i += 7;

        doc.text(15, i, element.Description);
        doc.text(110, i, element.Amount, {align:'right'});
        doc.text(140, i, currencySymbol + ' ' + Math.abs(element.Price).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."), {align:'right'});
        doc.text(170, i, element.Vat + '%', {align:'right'});
        doc.text(198, i, currencySymbol + ' ' + Math.abs(element.Subtotal).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."), {align:'right'});
      });

      doc.line(10, 212, 200, 212);

      doc.setFontType("bold");

      doc.text(130, 218, 'Sub total invoice');
      doc.text(130, 225, 'VAT total');

      doc.text(198, 218, currencySymbol + ' ' + Math.abs(exBTW).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."), {align:'right'});
      doc.text(198, 225, currencySymbol + ' ' + Math.abs(vat).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."), {align:'right'});

      doc.line(10, 228, 200, 228);

      doc.text(130, 235, 'Total');
      doc.text(198, 235, currencySymbol + ' ' + Math.abs(incBTW).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."), {align:'right'});

      doc.setFontType("normal");

      doc.setFontSize(8);

      doc.text(15, 245, 'Bank account: ' + from.bank);
      doc.text(15, 250, 'IBAN: ' + from.iban);
      doc.text(15, 255, 'BIC: ' + from.bic);
      doc.text(15, 260, 'VAT: ' + from.vat);
      doc.text(15, 265, 'Chamber of Commerce: ' + from.kvk);

      doc.setFontSize(10);

      doc.text(invoice.remarksAndConditions, doc.internal.pageSize.width / 2, 275, null, null, 'center');
      
      doc.save(invoice.invoiceNumber + "-" + to.companyName + '.pdf');
    }

    await storeInvoice (invoice, from, to, rows);
  }