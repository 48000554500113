export const toData = {
    holding: {
        companyName: "Merapar Technologies Holding B.V.",
        addressedTo: "Finance",
        street: "De Run 8417",
        city: "Veldhoven",
        postalCode: "5504 EP",
        country: "The Netherlands",
        vat: "NL857730927",
    },
    group: {
        companyName: "Merapar Technologies Group B.V.",
        addressedTo: "Finance",
        street: "De Run 8417",
        city: "Veldhoven",
        postalCode: "5504 EP",
        country: "The Netherlands",
        vat: "NL852742575",
    },
    nl: {
        companyName: "Merapar Technologies Netherlands B.V.",
        addressedTo: "Finance",
        street: "De Run 8417",
        city: "Veldhoven",
        postalCode: "5504 EP",
        country: "The Netherlands",
        vat: "NL856169420",
    },
    pl: {
        companyName: "Merapar Technologies Poland B.V.",
        addressedTo: "Finance",
        street: "De Run 8417",
        city: "Veldhoven",
        postalCode: "5504 EP",
        country: "The Netherlands",
        vat: "856977445",
    },
    uk: {
        companyName: "Merapar Technologies UK Ltd",
        addressedTo: "Finance",
        street: "453 Cranbrook Road",
        city: "Ilford",
        postalCode: "IG2 6EW",
        country: "United Kingdom",
        vat: "GB316277307",
    }
}